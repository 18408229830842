<template>
  <div>
    <b-row>
      <!-- card collapsible  -->
      <b-col md="12">
        <b-card-actions title="Filter" :collapsed="true" action-collapse>
          <b-row>
            <b-col cols="3">
              <b-form-group label="Nama" label-for="name">
                <b-form-input
                  id="name"
                  placeholder="Nama"
                  v-model="filter.name"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Kode" label-for="code">
                <b-form-input
                  id="code"
                  placeholder="Kode"
                  v-model="filter.code"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mt-1"
                @click="
                  getInquiry(
                    1,
                    filter.selectedOrganization,
                    filter.name,
                    filter.code
                  )
                "
              >
                Cari
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right mb-1">
        <router-link to="/catalog/add">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            pill
            class="px-3"
          >
            Tambah Katalog Baru
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-card>
      <!-- search input -->
      <!-- <b-row>
                <b-col class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search"
                                type="text"
                                class="d-inline-block"
                            />
                        </div>
                    </b-form-group>
                </b-col>
            </b-row> -->

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Price -->
          <span v-else-if="props.column.field === 'price'" class="text-nowrap">
            <span class="text-nowrap">{{ formatRupiah(props.row.price) }}</span>
          </span>

          <!-- Column: Selling Price -->
          <span
            v-else-if="props.column.field === 'selling_price'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{
              formatRupiah(props.row.selling_price)
            }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-bind:to="{ path: '/catalog/' + props.row.id }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="highlight(props.row.id)"
                  v-if="props.row.highlighted === 1"
                >
                  <feather-icon icon="XCircleIcon" class="mr-50" />
                  <span>Hapus dari beranda</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="highlight(props.row.id)"
                  v-if="props.row.highlighted === 0"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>Simpan diberanda</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteCatalog(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Hapus</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ meta.from }} - {{ meta.to }} </span>
              <span class="text-nowrap">&nbsp;dari {{ meta.total }} data </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) =>
                    getInquiry(
                      value,
                      filter.selectedOrganization,
                      filter.name,
                      filter.code
                    )
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormFile,
  BModal,
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BCardActions,
    VueGoodTable,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormFile,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Kode",
          field: "code",
          width: "150px",
        },
        {
          label: "Nama",
          field: "name",
        },
        {
          label: "Merk",
          field: "brand",
          width: "250px",
        },
        {
          label: "Kategori",
          field: "category_name",
          width: "250px",
        },
        {
          label: "",
          field: "action",
          width: "50px",
        },
      ],
      filter: {
        selectedOrganization: "",
        optionsOrganization: [],
        name: "",
        code: "",
      },
      search: "",
      rows: [],
      searchTerm: "",
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      permissions: [],
      errors: "",
      errMessage: "",
      file: null,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-success",
        0: "light-danger",
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getAllPermissions();
    this.getInquiry(1, "", "", "");
  },
  methods: {
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getInquiry($page, $organization, $name, $code) {
      this.$http
        .get(
          "catalogs?page=" +
            $page +
            ($organization
              ? "&organization=" + this.filter.selectedOrganization
              : "") +
            ($name ? "&name=" + this.filter.name : "") +
            ($code ? "&code=" + this.filter.code : "")
        )
        .then((res) => {
          this.rows = res.data.data;
          // console.log(res.data.data)
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteCatalog($id) {
      this.$http
        .post("catalog/" + $id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "katalog berhasil dihapus",
              variant: "danger",
            },
          });
          location.href = "/catalogs";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
    highlight($id) {
      this.$http
        .post("catalog/" + $id + "/highlight")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Katalog berhasil diperbaharui",
              variant: "success",
            },
          });
          location.href = "/catalogs";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>